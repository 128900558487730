export const SliderData = [
  {
    image: 'playdog3.png'
  },
  {
    image: 'playdog.png'
  },
  {
    image: 'playdog2.png'
  },
]

export const SliderDataTwo = [
  {
    image: 'immgrow1.png'
  },
  {
    image: 'immigrow2.png'
  },
  {
    image: 'immigrow3.png'
  },

]